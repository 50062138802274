import Vue from "vue"
import VueRouter from "vue-router"
Vue.use(VueRouter)

import Index from "./pages/index.vue"

// 部分懒加载路由以加快首页展示速度
const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  {
    path: "/newsdetail",
    name: "Newsdetail",
    component: (resolve) => require(["./pages/newsdetail.vue"], resolve),
  },
  {
    path: "/reply",
    name: "Reply",
    component: (resolve) => require(["./pages/reply.vue"], resolve),
  },
]

const router = new VueRouter({
  //mode: "history",
  mode: "hash",
  base: process.env.BASE_URL,
  routes: routes,
})

export default router
