<template>
  <div class="mainbody">
    <Navbar ref="webnavbar" />
    <Slider />
    <About />
    <Services />
    <Mall />
    <News :news="news" :pages="newspage"/>
    <Faq :faqs="faqdata" :empty="faqempty" />
    <Contact />
    <Heightfix />
    <Footer />
    <el-backtop :right="40" :bottom="50" />
  </div>
</template>
<script>
import Navbar from "@/components/navbar";
import Slider from "@/components/slider";

// 组件懒加载以加快首页展示速度
const About = () => import("@/components/about");
const Services = () => import("@/components/services");
const Mall = () => import("@/components/mall");
const Faq = () => import("@/components/faq");
const News = () => import("@/components/news");
const Contact = () => import("@/components/contact");
const Footer = () => import("@/components/footer");
const Heightfix = () => import("@/components/heightfix");
export default {
  data() {
    return {
      news: [],
      newspage:0,
      faqdata: [],
      faqempty: false,
    };
  },
  components: {
    Navbar,
    Slider,
    Services,
    About,
    Faq,
    Mall,
    News,
    Contact,
    Heightfix,
    Footer
  },
  mounted() {
    this.getnews(); //获取新闻列表
    this.getfaqdata(); //获取常见问题解答列表
  },
  methods: {
    getnews() {
      this.$axios
        .get("web/newslist")
        .then((res) => {
          if (res.status == "ok") {
            this.news = res.data;
          }
          this.newspage=Math.ceil(res.data.length/3);
        })
        .catch((err) => { });
    },
    getfaqdata() {
      this.$axios.get('web/faqlist').then(res => {
        if (res.status == 'ok') {
          this.faqdata = res.data;
          this.faqempty = false;
        } else {
          this.faqempty = true;
        }
      }).catch(err => { this.faqempty = true; })
    }
  },
};
</script>
<style>
::v-deep .modal-content {
  background-color: #000000;
  color: white;
  background: none;
  border: none;
}

::v-deep .modal-header {
  border: none;
}

::v-deep .modal-backdrop {
  opacity: 0.5;
}
</style>
