const setting = {
  color: {
    Main: "#ff5e5b",
    Fsub: "#ffa684",
    Ssub: "#ffa684",
    Tsub: "#ffa684",
    Radio: "#ff6a6a",
    CouponA: "linear-gradient(to top, #fc3f5b, #fe7f5f)",
    CouponB: "linear-gradient(to top, #fe7a3c, #ffb64d)",
    CouponC: "linear-gradient(to top, #de47a1, #ca66f3)",
    Gradual: "linear-gradient(to left, #ff5e5b,#ffa684)",
    HeaderStyle: "linear-gradient(to right, #ff5e5b,#ffa684)",
  },
  company: {
    Name: "山东纪元信息科技有限公司",
    Ename: "SHANDONG JIYUAN INFORMATION TECHNOLOGY CO.,LTD.",
    Address: "山东省潍坊市综合保税区高新二路888号",
    EAddress:"No.888,Gaoer Road,Comprehensive Free Trade Zone,WeiFang City,ShanDong Province,China",
    Web: "Http://www.jiyuanxinxi.net",
    Email: "jyxx@jiyuanxinxi.net",
    Tel: "00-86-536-8253622",
    Phone: "0536-8253622",
  },
  soft: {
    Name: "纪元乐购商城",
    Ename: "JIYUAN ONLINE SHOPPING MALL",
    Copyrights: "版权所有 2020 © 山东纪元信息科技有限公司",
    Version: "6.0.0",
  },
  author: {
    Name: "蔡召利",
    Phone: "18653786122",
    Email: "caizhaoli@163.com",
  },
};
export default Object.assign(setting);
