import Vue from "vue"
import vuex from "vuex"
Vue.use(vuex)
export default new vuex.Store({
  state: {
    language: "CN",
    user: {userid: 0, username: "", userphone: ""},
  },
  mutations: {
    changelanguage(state, newlanguage) {
      state.language = newlanguage
    },
    updateuser(state, user) {
      for (let k in user) {
        state.user[k] = user[k]
      }
    },
  },
})
