/* 常用功能函数 蔡召利 2021.09.12 */

//01、获取系统信息 systeminfo
//02、设置页面导航条 setNavBar
//03、动态设置页面标题 setPageTitle
//04、路由跳转 jumpn，jumpr，jumps，jumpback，relaunch
//05、单个元素选择 select
//06、多个元素获取 selectAll
//07、数组合并 arrayConcat
//08、删除数组内的指定元素 arrayDrop
//09、在数组内查找指定元素 arrayIndexOf
//10、比较两个数组的不同的值 arrayDifference
//11、数组随机排序 arrayShuffle
//12、对数值型数组求和 arraySum
//13、对数值型数组进行平均值计算 arrayAvg
//14、遍历数组 arrayEach
//15、两数之间的随机数 random
//16、获取唯一ID uuid
//17、对象元素赋值操作 assign
//18、对象元素删除操作 removeByKey
//19、对象遍历 each
//20、判断是否空对象 isEmptyObj
//21、json对象数组排序 getSort
//22、调用选取图像 chooseImgs
//23、获取图像信息 getImageInfo
//24、图像预览 previewImage
//25、获取特定时间 now
//26、时间戳转时间 toDate
//27、字符串转时间戳 toTimeStamp
//28、根据时间戳计算多少分钟/小时/天之前 fromTime
//29、计算两时间戳之间相隔多少分钟 minutesBetween
//30、根据时间戳计算时间在多少秒之前 secondsBetween
//31、求两个时间戳的天数差 daysBetween
//32、加时计算，输入时间戳返回加多少秒后的时间戳 timeAdd
//33、判断当前时间是否在一段时间之内 isDuringDate
//34、全部转为大写 toUpperCase
//35、全部转为小写 toLowerCase
//36、字符串长度计算 strcount
//37、截取字符串（支持中英文混合）strsub
//38、去除全部空格 removeAllSpace
//39、去除首尾空格 trim
//40、去除左侧空格 trimL
//41、去除右侧空格 trimR
//42、金额数字格式化 formatmoney
//43、内容替换 replaceAll
//44、脱敏格式化手机号码 formatPhonenum
//45、字符串搜索 search
//46、判断两个数字是否相等，支持区分-0和0 equal
//47、转换为字符类型 get_str
//48、转换为数字类型 get_num
//49、向上取整数 get_int_max
//50、向下取整数 get_int_min
//51、丢弃小数部分取整 get_int
//52、四舍五入 get_round
//53、浮点数相加求和 add
//54、保留特定位数小数四舍五入 get_floatnum
//55、金额汉字格式化 convertToChinaNum
//56、判断是否为空 _isNullOrEmpty
//57、判断是否为未定义 _isUndefined
//58、手机号码判断 _isMobile
//59、邮箱判断 _isEmail
//60、车牌号码校验 _isCarNo
//61、身份证号校验 _isIdCard
//62、两位小数金额格式判断 _isAmount
//63、数字格式判断 _isNum
//64、汉字格式判断 _isChinese
//65、英文格式判断 _isEnglish
//66、数字和字母组合判断 _isEnAndNo
//67、英文或数字（非汉字）判断 _isEnOrNo
//68、包含特殊字符判断 _isSpecial
//69、包含表情判断 _isEmoji
//70、Ymd格式日期判断 _isDate
//71、URL地址判断 _isUrl
//72、相同值判断 _isSame
//73、是否在区间判断 _isRange
//74、最小长度判断 _minLength
//75、最大长度判断 _maxLength
//76、获取扩展名 getExtension
//77、消息弹框 msg
//78、弹出加载动画 showLoading
//79、延迟操作 delay
//80、间隔指定时间循环某个函数 interval
//81、获取ref getRefs
//82、加密 encryption
//83、解密 decrypt
//84、防抖函数 debounce
//85、深度克隆 deepClone
//86、网络请求 get
//87、网络请求 post
//88、数据脱敏函数 hideCode
//89、判断非负整数 testnum
//90、JS对象深度合并 deepMerge
//91、验证是否包含某个值 contains
//92、验证是否在某个区间 range
//93、验证长度是否在某个区间 rangeLength
//94、获取Refs getRefs
//95、获取数组中元素出现次数 getCount

export default {
  //01、获取系统信息
  systeminfo: function () {
    try {
      var res = uni.getSystemInfoSync()
      var iPhoneXBottom = 0
      if (!res.model) {
        res.model = "no"
      }
      res.model = res.model.replace(" ", "")
      res.model = res.model.toLowerCase()
      var res1 = res.model.indexOf("iphonex")
      if (res1 > 5) {
        res1 = -1
      }
      var res2 = res.model.indexOf("iphone1")
      if (res2 > 5) {
        res2 = -1
      }
      if (res1 != -1 || res2 != -1) {
        res.iPhoneXBottomHeightRpx = 50
        res.iPhoneXBottomHeightPx = uni.upx2px(50)
      } else {
        res.iPhoneXBottomHeightRpx = 0
        res.iPhoneXBottomHeightPx = 0
      }
      return res
    } catch (e) {
      return null
    }
  },
  //02、页面导航条设置 参数sets:{title:"页面标题",color:{frontColor:"#ffffff", backgroundColor:"#ff0000"},loading:true}，可只提供必要参数
  setNavBar: function (sets) {
    if (sets.title) {
      uni.setNavigationBarTitle({
        title: sets.title,
      })
    }
    if (sets.color) {
      uni.setNavigationBarColor({
        frontColor: sets.color.frontColor,
        backgroundColor: sets.color.backgroundColor,
        animation: {
          duration: 400,
          timingFunc: "easeIn",
        },
      })
    }
    if (sets.loading) {
      uni.showNavigationBarLoading()
    } else {
      uni.hideNavigationBarLoading()
    }
  },
  //03、动态设置页面标题
  setPageTitle: function (title) {
    uni.setNavigationBarTitle({
      title: title,
    })
  },
  //04、路由跳转
  jumpn: function (url) {
    uni.navigateTo({
      url: url,
    })
  },
  jumpr: function (url) {
    uni.redirectTo({
      url: url,
    })
  },
  jumps: function (url) {
    uni.switchTab({
      url: url,
    })
  },
  jumpback: function (delta) {
    if (!delta) {
      delta = 1
    }
    uni.navigateBack({
      delta: delta,
    })
  },
  relaunch: function (url) {
    uni.reLaunch({
      url: url,
    })
  },
  //05、单个元素选择
  select: function (selector, callBack) {
    uni
      .createSelectorQuery()
      .select(selector)
      .boundingClientRect()
      .exec((res) => {
        callBack(res[0])
      })
  },
  //06、多个元素获取
  selectAll: function (selector, callBack) {
    uni
      .createSelectorQuery()
      .selectAll(selector)
      .boundingClientRect()
      .exec((res) => {
        callBack(res[0])
      })
  },
  //07、数组合并,合并多个数据
  arrayConcat: function () {
    var tmpArr = []
    for (let i = 0; i < arguments.length; i++) {
      tmpArr = tmpArr.concat(arguments[i])
    }
    return tmpArr
  },
  //08、删除数组内的指定元素
  arrayDrop: function (array, index, howmany) {
    if (!index) {
      index = 0
    }
    if (!howmany) {
      howmany = 1
    }
    array.splice(index, howmany)
    return array
  },
  //09、在数组内查找指定元素
  arrayIndexOf: function (arr, needFind) {
    var index = -1
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] == needFind) {
        index = i
        return i
      }
    }
    return index
  },
  //10、比较两个数组的不同的值
  arrayDifference: function (a, b) {
    const set = new Set(b)
    return a.filter((x) => !set.has(x))
  },
  //11、数组随机排序
  arrayShuffle: function (arr) {
    let l = arr.length
    while (l) {
      const i = Math.floor(Math.random() * l--)
      ;[arr[l], arr[i]] = [arr[i], arr[l]]
    }
    return arr
  },
  //12、对数值型数组求和
  arraySum: function (arr) {
    return arr.reduce((acc, val) => acc + val, 0)
  },
  //13、对数值型数组进行平均值计算
  arrayAvg: function (arr) {
    return arr.reduce((acc, val) => acc + val, 0) / arr.length
  },
  //14、遍历数组
  arrayEach: function (arr, fun) {
    for (let i = 0; i < arr.length; i++) {
      fun(arr[i], i)
    }
  },
  //15、两数之间的随机数
  random: function (min, max) {
    switch (arguments.length) {
      case 1:
        return parseInt(Math.random() * min + 1, 10)
        break
      case 2:
        return parseInt(Math.random() * (max - min + 1) + min, 10)
        break
      default:
        return 0
    }
  },
  //16、获取UUID
  uuid: function (len) {
    var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("")
    var uuid = [],
      i
    if (len) {
      for (i = 0; i < len; i++) {
        uuid[i] = chars[0 | (Math.random() * chars.length)]
      }
    } else {
      var r
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-"
      uuid[14] = "4"
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | (Math.random() * 16)
          uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r]
        }
      }
    }
    return uuid.join("")
  },
  //17、对象元素赋值操作
  assign: function (obj, key, val) {
    obj[key] = val
  },
  //18、对象元素删除操作
  removeByKey: function (obj, key) {
    delete obj[key]
  },
  //19、对象遍历
  each: function (obj, func) {
    for (let k in obj) {
      func(k, obj[k])
    }
  },
  //20、判断是否空对象
  isEmptyObj: function (obj) {
    return JSON.stringify(obj) === "{}"
  },
  //21、json对象数组排序,order为asc或desc
  getSort: function (sortBy, order) {
    var ordAlpah = order == "asc" ? ">" : "<"
    var sortFun = new Function("a", "b", "return a." + sortBy + ordAlpah + "b." + sortBy + "?1:-1")
    return sortFun
  },
  //22、调用选取图像
  chooseImgs: function (sets, success, fail, complete) {
    if (!sets.count) {
      sets.count = 1
    }
    if (!sets.sizeType) {
      sets.sizeType = ["original", "compressed"]
    }
    if (!sets.sourceType) {
      sets.sourceType = ["album", "camera"]
    }
    uni.chooseImage({
      count: sets.count,
      //默认9
      sizeType: sets.sizeType,
      //可以指定是原图还是压缩图，默认二者都有
      sourceType: sets.sourceType,
      //从相册选择
      success: (res) => {
        success(res.tempFilePaths)
      },
      fail: (e) => {
        if (fail) {
          fail(e)
        }
      },
      complete: (e) => {
        if (complete) {
          complete(e)
        }
      },
    })
  },
  //23、获取图像信息
  getImageInfo: function (imgUrl, success, fail, complete) {
    uni.getImageInfo({
      src: imgUrl,
      success: function (info) {
        success(info)
      },
      fail: (e) => {
        if (fail) {
          fail(e)
        }
      },
      complete: (e) => {
        if (complete) {
          complete(e)
        }
      },
    })
  },
  //24、图像预览
  previewImage: function (items, currentImg) {
    uni.previewImage({
      urls: items,
      current: currentImg,
    })
  },
  //25、获取特定时间
  now: function (type, addTime) {
    var dateObj = new Date()
    var cTime = dateObj.getTime()
    if (addTime) {
      cTime += addTime
    }
    if (!type) {
      type = "number"
    }
    if (type == "number") {
      return cTime
    } else if (type == "str") {
      return this.toDate(cTime / 1000, "str")
    } else if (type == "array") {
      return this.toDate(cTime / 1000, "array")
    }
  },
  //26、时间戳转时间 YY-mm-dd HH:ii:ss
  toDate: function (timeStamp, returnType) {
    timeStamp = parseInt(timeStamp)
    var date = new Date()
    if (timeStamp < 90000000000) {
      date.setTime(timeStamp * 1000)
    } else {
      date.setTime(timeStamp)
    }
    var y = date.getFullYear()
    var m = date.getMonth() + 1
    m = m < 10 ? "0" + m : m
    var d = date.getDate()
    d = d < 10 ? "0" + d : d
    var h = date.getHours()
    h = h < 10 ? "0" + h : h
    var minute = date.getMinutes()
    var second = date.getSeconds()
    minute = minute < 10 ? "0" + minute : minute
    second = second < 10 ? "0" + second : second
    if (returnType == "str") {
      return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second
    }
    if (returnType == "date") {
      return y + "-" + m + "-" + d
    }
    if (returnType == "datesecond") {
      return y + "-" + m + "-" + d + " " + h + ":" + minute
    }
    return [y, m, d, h, minute, second]
  },
  //27、字符串转时间戳
  toTimeStamp: function (timeStamp) {
    var reg = /^([0-9]{4})-([0-9]{2})-([0-9]{2}) ([0-9]{2}):([0-9]{2}):([0-9]{2})$/
    var res = timeStamp.match(reg)
    if (res == null) {
      var reg2 = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4}) ([0-9]{2}):([0-9]{2}):([0-9]{2})$/
      var res2 = timeStamp.match(reg2)
      if (res2 == null) {
        console.log("时间格式错误 E001")
        return false
      }
      var year = parseInt(res2[3])
      var month = parseInt(res2[1])
      var day = parseInt(res2[2])
      var h = parseInt(res2[4])
      var i = parseInt(res2[5])
      var s = parseInt(res2[6])
    } else {
      var year = parseInt(res[1])
      var month = parseInt(res[2])
      var day = parseInt(res[3])
      var h = parseInt(res[4])
      var i = parseInt(res[5])
      var s = parseInt(res[6])
    }
    if (year < 1000) {
      console.log("时间格式错误")
      return false
    }
    if (h < 0 || h > 24) {
      console.log("时间格式错误")
      return false
    }
    if (i < 0 || i > 60) {
      console.log("时间格式错误")
      return false
    }
    if (s < 0 || s > 60) {
      console.log("时间格式错误")
      return false
    }
    return Date.parse(new Date(year, month - 1, day, h, i, s))
  },
  //28、根据时间戳计算多少分钟/小时/天之前
  fromTime: function (time) {
    if (time < 90000000000) {
      time *= 1000
    }
    var timer = new Date().getTime() - time
    timer = parseInt(timer / 1000)
    if (timer < 180) {
      return "刚刚"
    } else if (timer >= 180 && timer < 3600) {
      return parseInt(timer / 60) + "分钟前"
    } else if (timer >= 3600 && timer < 86400) {
      return parseInt(timer / 3600) + "小时前"
    } else if (timer >= 86400 && timer < 2592000) {
      return parseInt(timer / 86400) + "天前"
    } else {
      return this.toDate(time, "str")
    }
  },
  //29、计算两时间戳之间相隔时间，单位分钟
  minutesBetween: function (start_time) {
    let end_time = Math.round(new Date().getTime() / 1000).toString()
    var startTime = 0,
      endTime = 0
    if (start_time < end_time) {
      startTime = start_time
      endTime = end_time
    } else {
      startTime = end_time
      endTime = start_time
    }
    var timeDiff = endTime - startTime
    var minute = Math.floor(timeDiff / 60)
    return minute
  },
  //30、根据时间戳计算时间在多少秒之前
  secondsBetween: function (time) {
    if (time < 90000000000) {
      time *= 1000
    }
    var timer = new Date().getTime() - time
    timer = parseInt(timer / 1000)
    return timer
  },
  //31、求两个时间戳的天数差
  daysBetween: function (dateString1, dateString2) {
    var daystemp = Math.abs(dateString1 - dateString2) / (1 * 24 * 60 * 60 * 1000)
    var days = parseInt(daystemp)
    return days
  },
  //32、加时计算，输入时间戳返回加多少秒后的时间戳
  timeAdd: function (time, secnods) {
    var date = new Date(time)
    date.setDate(date.getSeconds() + secnods)
    return Date.parse(date)
  },
  //33、判断当前时间是否在一段时间之内
  isDuringDate: function (endDateStr) {
    var curDate = new Date(),
      beginDate = new Date("2020-01-01 01:01:01"),
      endDate = new Date(endDateStr)
    if (curDate >= beginDate && curDate <= endDate) {
      return true
    }
    return false
  },
  //34、全部转为大写
  toUpperCase: function (str) {
    return str.toUpperCase()
  },
  //35、全部转为小写
  toLowerCase: function (str) {
    return str.toLowerCase()
  },
  //36、字符串长度计算
  strcount: function (str) {
    var totalCount = 0
    for (var i = 0; i < str.length; i++) {
      var c = str.charCodeAt(i)
      if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
        totalCount++
      } else {
        totalCount += 2
      }
    }
    return totalCount
  },
  //37、截取字符串（支持中英文混合）
  strsub: function (str, n) {
    var r = /[^\x00-\xff]/g
    if (str.replace(r, "mm").length <= n) {
      return str
    }
    var m = Math.floor(n / 2)
    for (var i = m; i < str.length; i++) {
      if (str.substr(0, i).replace(r, "mm").length >= n) {
        return str.substr(0, i) + "..."
      }
    }
    return str
  },
  //38、去除全部空格
  removeAllSpace: function (str) {
    return str.replace(/\s+/g, "")
  },
  //39、去除首尾空格
  trim: function (str) {
    return str.trim()
  },
  //40、去除左侧空格
  trimL: function (str) {
    return str.replace(/^\s+/g, "")
  },
  //41、去除右侧空格
  trimR: function (str) {
    return str.replace(/\s+$/g, "")
  },
  //42、金额数字格式化
  formatmoney: function (money) {
    return parseFloat(money)
      .toFixed(2)
      .toString()
      .split("")
      .reverse()
      .join("")
      .replace(/(\d{3})/g, "$1,")
      .replace(/\,$/, "")
      .split("")
      .reverse()
      .join("")
  },
  //43、内容替换
  replaceAll: function (text, repstr, newstr) {
    return text.replace(new RegExp(repstr, "gm"), newstr)
  },
  //44、格式化手机号码
  formatPhonenum: function (num) {
    return num.length === 11 ? num.replace(/^(\d{3})\d{4}(\d{4})$/, "$1****$2") : num
  },
  //45、字符串搜索
  search: function (str, kwd, caseSensitive = true) {
    if (!caseSensitive) {
      kwd = kwd.toLowerCase()
      str = str.toLowerCase()
    }
    return str.indexOf(kwd)
  },
  //46、判断两个数字是否相等，支持区分 -0 和 0
  equal: function (a, b) {
    if (a !== b) {
      return false
    }
    return 1 / a === 1 / b
  },
  //47、转换为字符类型
  get_str: function (str) {
    return String(str)
  },
  //48、转换为数字类型
  get_num: function (str) {
    return Number(str)
  },
  //49、向上取整数
  get_int_max: function (num) {
    let number = Number(num)
    return Math.ceil(number)
  },
  //50、向下取整数
  get_int_min: function (num) {
    let number = Number(num)
    return Math.floor(number)
  },
  //51、丢弃小数部分取整
  get_int: function (num) {
    let number = Number(num)
    return parseInt(number)
  },
  //52、四舍五入 num 目标数值 decimals 精确小数位个数
  get_round: function (num, decimals = 0) {
    return Number(Math.round(num + "e" + decimals) + "e-" + decimals)
  },
  //53、浮点数相加求和 a （被）加数 b 加数 [decimals=0] 精确小数位个数
  add: function (a, b, decimals = 0) {
    return round((a + b) * Math.pow(10, decimals)) / Math.pow(10, decimals)
  },
  //54、保留特定位数小数四舍五入
  get_floatnum: function (num, count) {
    let number = Number(num)
    let value = number.toFixed(count)
    return value
  },
  //55、金额汉字格式化
  convertToChinaNum: function (num) {
    var arr1 = new Array("零", "一", "二", "三", "四", "五", "六", "七", "八", "九")
    var arr2 = new Array("", "十", "百", "千", "万", "十", "百", "千", "亿", "十", "百", "千", "万", "十", "百", "千", "亿") //可继续追加更高位转换值
    if (!num || isNaN(num)) {
      return "零"
    }
    var english = num.toString().split("")
    var result = ""
    for (var i = 0; i < english.length; i++) {
      var des_i = english.length - 1 - i //倒序排列设值
      result = arr2[i] + result
      var arr1_index = english[des_i]
      result = arr1[arr1_index] + result
    } //将【零千、零百】换成【零】 【十零】换成【十】
    result = result.replace(/零(千|百|十)/g, "零").replace(/十零/g, "十") //合并中间多个零为一个零
    result = result.replace(/零+/g, "零") //将【零亿】换成【亿】【零万】换成【万】
    result = result.replace(/零亿/g, "亿").replace(/零万/g, "万") //将【亿万】换成【亿】
    result = result.replace(/亿万/g, "亿") //移除末尾的零
    result = result.replace(/零+$/, "") //将【零一十】换成【零十】 //result = result.replace(/零一十/g, '零十');//貌似正规读法是零一十 //将【一十】换成【十】
    result = result.replace(/^一十/g, "十")
    return result
  },
  //56、判断是否为空
  _isNullOrEmpty: function (value) {
    switch (typeof value) {
      case "undefined":
        return true
      case "string":
        if (value.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, "").length == 0) return true
        break
      case "boolean":
        if (!value) return true
        break
      case "number":
        if (0 === value || isNaN(value)) return true
        break
      case "object":
        if (null === value || value.length === 0) return true
        for (var i in value) {
          return false
        }
        return true
    }
    return false
  },
  //57、判断是否为未定义
  _isUndefined: function (value) {
    return typeof value === "undefined"
  },
  //58、手机号码判断
  _isMobile: function (value) {
    return /^(?:13\d|14\d|15\d|16\d|17\d|18\d|19\d)\d{5}(\d{3}|\*{3})$/.test(value)
  },
  //59、邮箱判断
  _isEmail: function (value) {
    return /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(value)
  },
  //60、车牌号码校验
  _isCarNo: function (value) {
    // 新能源车牌
    const xreg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/
    // 旧车牌
    const creg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/
    if (value.length === 7) {
      return creg.test(value)
    } else if (value.length === 8) {
      return xreg.test(value)
    } else {
      return false
    }
  },
  //61、身份证号校验
  _isIdCard: function (value) {
    let idCard = value
    if (idCard.length == 15) {
      return this.__isValidityBrithBy15IdCard
    } else if (idCard.length == 18) {
      let arrIdCard = idCard.split("")
      if (this._isValidityBrithBy18IdCard(idCard) && this._isTrueValidateCodeBy18IdCard(arrIdCard)) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  },
  _isTrueValidateCodeBy18IdCard: function (arrIdCard) {
    let sum = 0
    let Wi = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1]
    let ValideCode = [1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2]
    if (arrIdCard[17].toLowerCase() == "x") {
      arrIdCard[17] = 10
    }
    for (let i = 0; i < 17; i++) {
      sum += Wi[i] * arrIdCard[i]
    }
    let valCodePosition = sum % 11
    if (arrIdCard[17] == ValideCode[valCodePosition]) {
      return true
    } else {
      return false
    }
  },
  _isValidityBrithBy18IdCard: function (idCard18) {
    let year = idCard18.substring(6, 10)
    let month = idCard18.substring(10, 12)
    let day = idCard18.substring(12, 14)
    let temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day))
    if (temp_date.getFullYear() != parseFloat(year) || temp_date.getMonth() != parseFloat(month) - 1 || temp_date.getDate() != parseFloat(day)) {
      return false
    } else {
      return true
    }
  },
  _isValidityBrithBy15IdCard: function (idCard15) {
    let year = idCard15.substring(6, 8)
    let month = idCard15.substring(8, 10)
    let day = idCard15.substring(10, 12)
    let temp_date = new Date(year, parseFloat(month) - 1, parseFloat(day))

    if (temp_date.getYear() != parseFloat(year) || temp_date.getMonth() != parseFloat(month) - 1 || temp_date.getDate() != parseFloat(day)) {
      return false
    } else {
      return true
    }
  },
  //62、两位小数金额格式判断
  _isAmount: function (value) {
    //金额，只允许保留两位小数
    return /^([0-9]*[.]?[0-9])[0-9]{0,1}$/.test(value)
  },
  //63、数字格式判断
  _isNum: function (value) {
    //只能为数字
    return /^[0-9]+$/.test(value)
  },
  //64、汉字格式判断
  _isChinese: function (value) {
    let reg = /^[\u4e00-\u9fa5]+$/
    return reg.test(value)
  },
  //65、英文格式判断
  _isEnglish: function (value) {
    return /^[a-zA-Z]*$/.test(value)
  },
  //66、数字和字母组合判断
  _isEnAndNo: function (value) {
    //8~20位数字和字母组合
    return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/.test(value)
  },
  //67、英文或数字（非汉字）判断
  _isEnOrNo: function (value) {
    //英文或者数字
    let reg = /.*[\u4e00-\u9fa5]+.*$/
    let result = true
    if (reg.test(value) || form._isSpecial(value) || form._isEmoji(value)) {
      result = false
    }
    return result
  },
  //68、包含特殊字符判断
  _isSpecial: function (value) {
    //是否包含特殊字符
    let regEn = /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im,
      regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im
    if (regEn.test(value) || regCn.test(value)) {
      return true
    }
    return false
  },
  //69、包含表情判断
  _isEmoji: function (value) {
    //是否包含表情
    return /\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g.test(value)
  },
  //70、Ymd格式日期判断
  _isDate: function (value) {
    //2019-10-12
    const reg = /^(?:(?!0000)[0-9]{4}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-8])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)|(?:[0-9]{2}(?:0[48]|[2468][048]|[13579][26])|(?:0[48]|[2468][048]|[13579][26])00)-02-29)$/
    return reg.test(value)
  },
  //71、URL地址判断
  _isUrl: function (value) {
    return /^((https?|ftp|file):\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/.test(value)
  },
  //72、相同值判断
  _isSame: function (value1, value2) {
    return value1 === value2
  },
  //73、是否在区间判断
  _isRange: function (value, range1, range2) {
    if (!range1 && range1 != 0 && !range2 && range2 != 0) {
      return true
    } else if (!range1 && range1 != 0) {
      return value <= range2
    } else if (!range2 && range2 != 0) {
      return value >= range1
    } else {
      return value >= range1 && value <= range2
    }
  },
  //74、最小长度判断
  _minLength: function (value, min) {
    return value.length >= Number(min)
  },
  //75、最大长度判断
  _maxLength: function (value, max) {
    return value.length <= Number(max)
  },
  //76、获取扩展名
  getExtension: function (str) {
    str = str.split(".")
    return str.pop()
  },
  //77、消息弹框
  msg: function (msg) {
    uni.showToast({
      title: msg,
      icon: "none",
      position: "bottom",
    })
  },
  //78、弹出加载动画
  showLoading: function (title) {
    uni.showLoading({
      title: title,
      mask: true,
    })
  },
  //79、延迟操作
  delay: function (timer, func) {
    return setTimeout(func, timer)
  },
  //80、间隔指定时间循环某个函数
  interval: function (timer, func) {
    return setInterval(func, timer)
  },
  //81、获取ref ( 循环获取，直到组件创建完成并获取成功 )
  getRefs: function (ref, _this, count, fun) {
    if (count >= 40) {
      return null
    }
    var refReturn = _this.$refs[ref]
    if (refReturn) {
      fun(refReturn)
      return
    } else {
      count++
      setTimeout(() => {
        this.getRefs(ref, _this, count, fun)
      }, 100)
    }
  },
  //82、加密
  encryption: function (code) {
    var c = String.fromCharCode(code.charCodeAt(0) + code.length)
    for (var i = 1; i < code.length; i++) {
      c += String.fromCharCode(code.charCodeAt(i) + code.charCodeAt(i - 1))
    }
    return escape(c)
  },
  //83、解密
  decrypt: function (code) {
    code = unescape(code)
    var c = String.fromCharCode(code.charCodeAt(0) - code.length)
    for (var i = 1; i < code.length; i++) {
      c += String.fromCharCode(code.charCodeAt(i) - c.charCodeAt(i - 1))
    }
    return c
  },
  //84、防抖函数
  debounce: function (fn, delay = 1000) {
    // 定时器，用来 setTimeout
    let timer
    return function (...args) {
      // 保存函数调用时的上下文和参数，传递给 fn
      const context = this
      // 每次这个返回的函数被调用，就清除定时器，以保证不执行 fn
      clearTimeout(timer)
      // 当返回的函数被最后一次调用后（也就是用户停止了某个连续的操作），
      // 再过 delay 毫秒就执行 fn
      timer = setTimeout(function () {
        fn.apply(context, args)
      }, delay)
    }
  },
  //85、深度克隆
  deepClone: function (obj) {
    if (!obj || typeof obj !== "object") {
      return obj
    }
    // 根据obj的类型判断是新建一个数组还是对象
    let newObj = obj instanceof Array ? [] : {}
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[key] = typeof obj[key] === "object" ? deepClone(obj[key]) : obj[key]
      }
    }
    return newObj
  },
  //86、网络请求get
  get: function (url, data, headers, success, fail) {
    if (!fail) {
      fail = () => {
        this.msg("网络请求失败")
      }
    }
    if (!headers) {
      headers = {}
    }
    if (this.__before != null) {
      this.__before()
      this.__before = null
    }
    uni.request({
      url: url,
      data: data,
      method: "GET",
      dataType: "json",
      header: headers,
      success: (res) => {
        success(res.data)
      },
      fail: fail,
      complete: () => {
        if (this.__after != null) {
          this.__after()
          this.__after = null
        }
      },
    })
  },
  //87、网络请求post
  post: function (url, data, contentType, headers, success, fail) {
    if (!fail) {
      fail = () => {
        this.msg("网络请求失败")
      }
    }
    if (!headers) {
      headers = {}
    }
    if (!contentType) {
      contentType = "form"
    }
    if (this.__before != null) {
      this.__before()
      this.__before = null
    }
    switch (contentType) {
      case "form":
        headers["content-type"] = "application/x-www-form-urlencoded"
        break
      case "json":
        headers["content-type"] = "application/json"
        break
      default:
        headers["content-type"] = "application/x-www-form-urlencoded"
    }
    uni.request({
      url: url,
      data: data,
      method: "POST",
      dataType: "json",
      header: headers,
      success: (res) => {
        success(res.data)
      },
      fail: fail,
      complete: () => {
        if (this.__after != null) {
          this.__after()
          this.__after = null
        }
      },
    })
  },
  // 请求前置函数
  __before: null,
  setBefore: function (func) {
    this.__before = func
  },
  // 请求后置函数
  __after: null,
  setAfter: function (func) {
    this.__after = func
  },
  //88、数据脱敏函数
  //中文名字：只展示姓名第一个字
  //身份证号：年月日脱敏，前6后4不脱敏
  //手机号：前三后四不脱敏，中间脱敏
  //银行卡：前6位和后4位不脱敏，中间脱敏
  hideCode: function (str, frontLen, endLen) {
    var len = str.length - frontLen - endLen
    var xing = ""
    for (var i = 0; i < len; i++) {
      xing += "∗"
    }
    return str.substring(0, frontLen) + xing + str.substring(str.length - endLen)
  },
  //89、判断是否为非负整数
  testnum: function (data) {
    var reg = /^[0-9]+?$/
    //如果正则需要判断非负整数并带2位小数点，请使用   var reg = /^(([1-9]+)|([0-9]+\.[0-9]{1,2}))$/;
    if (reg.test(data)) {
      return true
    } else {
      return false
    }
  },
  //90、JS对象深度合并
  deepMerge: function (target = {}, source = {}) {
    target = deepClone(target)
    if (typeof target !== "object" || typeof source !== "object") return false
    for (var prop in source) {
      if (!source.hasOwnProperty(prop)) continue
      if (prop in target) {
        if (typeof target[prop] !== "object") {
          target[prop] = source[prop]
        } else {
          if (typeof source[prop] !== "object") {
            target[prop] = source[prop]
          } else {
            if (target[prop].concat && source[prop].concat) {
              target[prop] = target[prop].concat(source[prop])
            } else {
              target[prop] = deepMerge(target[prop], source[prop])
            }
          }
        }
      } else {
        target[prop] = source[prop]
      }
    }
    return target
  },
  //91、验证是否包含某个值
  contains: function (value, param) {
    return value.indexOf(param) >= 0
  },
  //92、验证值是否在某个区间
  range: function (value, param) {
    return value >= param[0] && value <= param[1]
  },
  //93、验证长度是否在某个区间
  rangeLength: function (value, param) {
    return value.length >= param[0] && value.length <= param[1]
  },
  //94、获取refs
  getRefs: function (ref, _this, count, fun) {
    if (count >= 40) {
      return null
    }
    var refReturn = _this.$refs[ref]
    if (refReturn) {
      fun(refReturn)
      return
    } else {
      count++
      setTimeout(() => {
        this.getRefs(ref, _this, count, fun)
      }, 100)
    }
  },
  //95、获取数组中元素出现次数 getCount
  getCount: function (arr, rank, ranktype) {
    //rank 返回前几个
    var obj = {},
      k,
      arr1 = []
    for (var i = 0, len = arr.length; i < len; i++) {
      k = arr[i]
      if (obj[k]) obj[k]++
      else obj[k] = 1
    }
    //保存结果{el-'元素'，count-出现次数}
    for (var o in obj) {
      arr1.push({
        el: o,
        count: obj[o],
      })
    }
    //排序（降序）
    arr1.sort(function (n1, n2) {
      return n2.count - n1.count
    })
    //如果ranktype为1，则为升序，反转数组
    if (ranktype === 1) {
      arr1 = arr1.reverse()
    }
    var rank1 = rank || arr1.length
    return arr1.slice(0, rank1)
  },
  isModile: function () {
    if (window.innerWidth <= 768) {
      return true
    }
    return false
  },
}
