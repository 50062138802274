import axios from 'axios';
import qs from 'qs';
import { Loading } from 'element-ui';
import { Message } from 'element-ui';

//创建axios实例 
var ajax = axios.create({
	method: 'post', //默认
	timeout: 6000, //设置超时
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;',
		'token': '5A3F147C2AEE122760FB21F47DD3DD4C',
	},
	responseType: 'json'
})
let isloading;
let loadingInstance;
let requestCount = 0 //正在请求的数量
//显示loading
const showLoading = () => {
	if (requestCount === 0 && !isloading) {
		loadingInstance = Loading.service();
		// loadingInstance = Loading.service({
		// 	lock: true,
		// 	text: 'Loading',
		// 	spinner: 'el-icon-loading',
		// 	background: 'rgba(0, 0, 0, 0.7)'
		// });
	}
	requestCount++;
}
//隐藏loading
const hideLoading = () => {
	requestCount--
	if (requestCount == 0) {
		loadingInstance.close();
	}
}
//请求拦截器 
axios.interceptors.request.use((config) => {
	showLoading()
	if (config.method === 'post') {
		config.data = qs.stringify(config.data);
	}
	return config;
}, (error) => Promise.reject(error));
//响应拦截器
axios.interceptors.response.use((response) => {
	hideLoading();
	return response.data;
}, (error) => {
	hideLoading();
	if (error.response && error.response.status) {
		const status = error.response.status
		switch (status) {
			case 'error':
				message = '未检索到数据';
				break;
			case 404:
				message = '请求地址出错';
				break;
			default:
				message = '请求失败'
		}
		Message(message);
		return Promise.reject(error);
	}
	return Promise.reject(error);
});
export default axios;
